<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">Deposit / Refund 일괄등록</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-40">
                <div class="mt-10 txt-right">
                    <a href="http://carrot.mxm.kr/reumdata/sample/sample.xlsx" target="_blank"><span class="btn-view ">양식 다운로드</span></a>
                    <div class="clear"></div>
                </div>
                <div class="mt-20">
                    <CarrotFileUploader ref="upfile"></CarrotFileUploader>
                </div>
            </div>
            <div class="layer-btn mt-20 txt-center">
                <button @click="pop.doSubmit()" class="btn-default dp-inblock">등록</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import Swal from 'sweetalert2';

export default {
    name: 'TutorTextbookExcelPopup',

    props: {
    },

    setup(props, {emit}) {
        const toast = useToast()

        const upfile = ref(null);

        const pop = reactive({
            doSubmit : () => {
                var formData = new FormData();

                if(upfile.value.file.filedata!==null) {
                    formData.append("title", upfile.value.file.filename);
                    formData.append("upfile", upfile.value.file.filedata);
                } else {
                    toast.error("파일을 선택하세요.");
                    return;
                }

                Swal.fire({
                    title : 'Deposit / Refund 일괄등록',
                    text  : '파일을 등록 하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        axios.post('/rest/resourceManagement/uploadDpRfExcel', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("등록 하였습니다.");
                                emit('close');
                            } else if ( res.data.err == 402 ) {
                                let msg = res.data.err_msg + '<br>';
                                for(let i=0; i<res.data.res.length; i++){
                                    let irow = res.data.res[i];
                                    msg += '[ ' + irow.num + ' ]라인 :: ' + irow.err + '<br>';
                                }
                                Swal.fire({
                                    title : '오류',
                                    html  : msg
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });
        
        onMounted(() => {
            // Mounted
        });

        return {pop, upfile};
    }
}
</script>
<style lang="scss" scoped>
</style>