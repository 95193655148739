<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <RefundPaymentPopup :idx="pop.idx" v-if="pop.isShow" @close="pop.hideRefund()"></RefundPaymentPopup>
        <TutorTextbookExcelPopup :idx="pop.idx" v-if="pop.isShowUp" @close="pop.hideUpExcel()"></TutorTextbookExcelPopup>

        <CarrotTitle title="강사용 교재 신청하기">
            - 필요한 강사용 교재는 [강사용 신청하기] 버튼 클릭 후 등록해 주시기 바랍니다.<br>
            - 교재명을 클릭하면 요청상세내용을 보실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">출고희망일</th>
                                <td>
                                    <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock"></carrot-date-picker> 
                                    ~ 
                                    <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock"></carrot-date-picker>
                                </td>
                                <th width="180">교재명</th>
                                <td>
                                    <carrot-book-title v-model:bookname.sync="bbs.title" v-model="bbs.idx_title" class="w-80per"></carrot-book-title>
                                </td>
                            </tr>
                            <tr>
                                <th width="180">강사명</th>
                                <td>
                                    <input type="text" v-model.trim="bbs.tutorname" class="w-80per">
                                </td>
                                <th width="180">클래스명</th>
                                <td>
                                    <input type="text" v-model.trim="bbs.classname" class="w-80per">
                                </td>
                            </tr>
                            <tr>
                                <th width="180">요청자</th>
                                <td colspan="3">
                                    <carrot-team :idx_office="bbs.office" v-model="bbs.team" class="w-40per"></carrot-team>
                                    <carrot-staff :idx_team="bbs.team" orderby="easc" v-model="bbs.staff" class="w-40per ml-10"></carrot-staff>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="bbs.doInit" class="btn-default float-right mt-10">검색</button>
                    <div class="clear"></div>

                    <div class="mt-40 mb-20">
                        <select @change="bbs.doInit()" v-model="bbs.btype" class="w-100px h-32px float-left">
                            <option value="">구분 전체</option>
                            <option value="내부">내부</option>
                            <option value="외부">외부</option>
                        </select>
                        <select @change="bbs.doInit" v-model="bbs.state" class="w-130px h-32px float-left ml-10">
                            <option value="">반납현황 전체</option>
                            <option value="CANCEL">취소</option>
                            <option value="DONE">완료</option>
                            <option value="REQUEST">요청</option>
                        </select>

                        <button  @click="bbs.downExcel()" class="btn-default float-left ml-10">엑셀 다운로드</button>
                        <button @click="pop.showUpExcel()" class="btn-default float-left ml-10">엑셀 일괄등록</button>
                        
                        <button @click="bbs.addTutorRental()" class="btn-default float-right">강사용 신청하기</button>
                        <button v-if="bbs.isAuth==true" @click="bbs.doDone()" class="btn-default float-right mr-10">출고완료</button>
                        <button v-if="bbs.isAuth==true" @click="bbs.doCancel()" class="btn-default float-right mr-10">출고취소</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="40">
                            <col width="50">
                            <col width="50">
                            <col width="*">
                            <col width="50">
                            <col width="120">
                            <col width="200">
                            <col width="120">
                            <col width="100">
                            <col width="50">
                            <col width="120">
                            <col width="80">
                            <col width="80">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <label><input type="checkbox" @change="bbs.checkAll" v-model="bbs.isAll"></label>
                                </th>
                                <th>No</th>
                                <th>교재</th>
                                <th>교재명</th>
                                <th>소속</th>
                                <th>강사명</th>
                                <th>클래스명</th>
                                <th>요청자</th>
                                <th>출고희망일</th>
                                <th>출고<br>현황</th>
                                <th>처리자</th>
                                <th>Deposit</th>
                                <th>Refund</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>
                                    <label v-if="irow.is_return=='N'"><input type="checkbox" v-model="bbs.selected" :value="irow.idx"></label>
                                </td>
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.btype }}</td>
                                <td class="txt-left txt-ellipsis">
                                    <span @click="bbs.showTutorRental(irow.idx)" class="btn-view">{{ irow.bookname }}</span>
                                </td>
                                <td>{{ irow.tutortype }}</td>
                                <td class="txt-ellipsis">{{ irow.tutorname }}</td>
                                <td class="txt-ellipsis">{{ irow.idx_class>0?irow.title:'-' }}</td>
                                <td class="txt-ellipsis">{{ irow.staff_ename }} ({{ irow.staff_kname }})</td>
                                <td>{{ irow.request_date }}</td>
                                <td>
                                    <span v-if="irow.state=='REQUEST'" class="color-1">요청</span>
                                    <span v-if="irow.state=='DONE'" >완료</span>
                                    <span v-if="irow.state=='CANCEL'">취소</span>
                                </td>
                                <td>
                                    {{ irow.mgr_ename?irow.mgr_ename+'('+ irow.mgr_kname+')':'-' }}
                                </td>
                                <td>
                                    <span v-if="irow.state=='REQUEST'">-</span>
                                    <span v-if="irow.state=='DONE'&&irow.is_deposit=='N'" @click="bbs.doDeposit(irow.idx)" class="btn-view">Deposit</span>
                                    <span v-if="irow.is_deposit=='Y'">완료</span>
                                </td>
                                <td>
                                    <span v-if="irow.state=='REQUEST'">-</span>
                                    <span v-if="irow.state=='DONE'&&irow.is_refund=='N'" @click="pop.showRefund(irow)" class="btn-view">지급하기</span>
                                    <span v-if="irow.is_refund=='Y'">지급완료</span>
                                </td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="13">등록된 출고 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotBookTitle from '@/components/common/CarrotBookTitle.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import RefundPaymentPopup from '@/components/popup/ResourceManagement/RefundPaymentPopup.vue'
import TutorTextbookExcelPopup from '@/components/popup/ResourceManagement/TutorTextbookExcelPopup.vue'


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotDatePicker,
        CarrotBookTitle,
        CarrotTeam,
        CarrotStaff,
        RefundPaymentPopup,
        TutorTextbookExcelPopup,
    },

    setup() {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            idx : 0,
            // Popup 노출 여부
            isShow: false,
            isShowUp : false,

            showRefund : (o) => {
                pop.idx = o.idx;

                if( o.tutortype == 'CTM' ){
                    pop.isShow = true;
                } else {
                    Swal.fire({
                        title : 'Refund',
                        text  : '해당 강사에게 Refund를 지급하겠습니까?',
                        showCancelButton : true
                    }).then((res) => {
                        if( res.isConfirmed == true ){
                            let params = {
                                idx       : o.idx,
                                idx_class : o.idx_class
                            };
                            axios.post("/rest/resourceManagement/setTutorBookRefund", params).then((res) => {
                                if( res.data.err == 0 ){
                                    toast.success("지급하였습니다.");
                                    bbs.doSearch();
                                } else {
                                    if(res.data.err_msg) toast.error(res.data.err_msg);
                                }
                            });
                        }
                    });
                }
            },
            hideRefund : () => {
                pop.isShow = false;
                bbs.doSearch();
            },
            showUpExcel : () => {
                pop.isShowUp = true;
            },
            hideUpExcel : () => {
                pop.isShowUp = false;
                bbs.page = 1;
                bbs.doSearch();
            }
        });
        

        const bbs = reactive({
            isAuth    : false,
            isMounted : false,
            isAll : false,
            selected : [],
            page : 1,
            rows : 50,

            sdate     : "",
            edate     : "",
            bookname  : "",
            book      : 0,
            tutorname : "",
            classname : "",
            office    : "99999",
            team      : 0,
            staff     : 0,

            state     : "",
            btype     : "",

            list : [], total : 0,

            checkAll : () => {
                if( bbs.isAll == true ){
                    for(let i=0; i<bbs.list.length; i++){
                        let irow = bbs.list[i];
                        bbs.selected.push(irow.idx);
                    }
                } else {
                    bbs.isAll    = false;
                    bbs.selected = [];
                }
            },

            downExcel : () => {
              location.href = "https://api.carrotians.net/excel/tutor_textbook_list"
                            + "?sdate=" + bbs.sdate
                            + "&edate=" + bbs.edate
                            + "&book="  + bbs.book
                            + "&tutorname=" + bbs.tutorname
                            + "&classname=" + bbs.classname
                            + "&team="  + bbs.team
                            + "&staff=" + bbs.staff
                            + "&state=" + bbs.state
                            + "&btype=" + bbs.btype;
            },

            showTutorRental : (idx) => {
                bbs.setStorage();

                router.push({
                    name : 'ResourceManagement-TutorTextbookView-idx',
                    params : {
                        idx : idx
                    }
                });
            },

            doDeposit : (idx) => {
                Swal.fire({
                    title : 'Deposit',
                    text  : '해당 강사에게 Deposit을 부여하겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx : idx
                        };
                        axios.post("/rest/resourceManagement/setTutorBookDeposit", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("부여하였습니다.");
                                bbs.doSearch();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doDone : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : '출고완료',
                        text  : '완료할 교재를 선택하세요.'
                    });
                    return;
                }

                Swal.fire({
                    title : '출고완료',
                    text  : '총 ' + bbs.selected.length + '건의 교재 출고를 완료하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : bbs.selected
                        };
                        axios.post("/rest/resourceManagement/doneTutorBookRental", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("완료하였습니다.");
                                bbs.doInit();
                            } else if ( res.data.err == 200 ){
                                let msg = [];
                                let canceled = res.data.canceled;
                                for(let i=0; i<bbs.list.length; i++){
                                    let irow = bbs.list[i];
                                    if( canceled.indexOf(irow.idx) >= 0 ){
                                        msg.push( '[' + irow.title + ']' );
                                    }
                                }
                                msg.join('<br>');
                                Swal.fire({
                                    title : '출고완료',
                                    text  : msg + ' 출고 요청이 재고 부족으로 출고할 수 없습니다.'
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                });
            },

            doCancel : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : '출고취소',
                        text  : '취소할 교재를 선택하세요.'
                    });
                    return;
                }

                Swal.fire({
                    title : '출고취소',
                    text  : '총 ' + bbs.selected.length + '건의 교재 출고를 취소하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : bbs.selected
                        };
                        axios.post("/rest/resourceManagement/cancelTutorBookRental", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("취소하였습니다.");
                                bbs.doInit();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                });
            },

            setStorage : () => {
                sessionStorage.setItem('TutorTextbookList', JSON.stringify({
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate   : bbs.sdate,
                    edate   : bbs.edate,
                    book    : bbs.book,
                    tutorname : bbs.tutorname,
                    classname : bbs.classname,
                    team    : bbs.team,
                    staff   : bbs.staff,
                    state   : bbs.state,
                    btype   : bbs.btype,
                }));
            },

            addTutorRental : () => {
                bbs.setStorage();

                router.push({
                    name : 'ResourceManagement-TutorTextbookAdd'
                });
            },

            doInit : () => {
                bbs.page = 1;
                bbs.isAll = false;
                bbs.selected = [];
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate   : bbs.sdate,
                    edate   : bbs.edate,
                    book    : bbs.book,
                    tutorname : bbs.tutorname,
                    classname : bbs.classname,
                    team    : bbs.team,
                    staff   : bbs.staff,
                    state   : bbs.state,
                    btype   : bbs.btype,
                };
                axios.get('/rest/resourceManagement/getTutorBookRentalList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAuth = res.data.is_auth=='Y'?true:false;

                        bbs.isAll = false;
                        bbs.selected = [];
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },


        });


        onMounted(() => {
            // Mounted
            bbs.isMounted = true;

            let ss = sessionStorage.getItem('TutorTextbookList');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.page ) bbs.page = ss.page;
                if( ss.rows ) bbs.rows = ss.rows;
                if( ss.sdate     ) bbs.sdate     = ss.sdate;
                if( ss.edate     ) bbs.edate     = ss.edate;
                if( ss.book      ) bbs.book      = ss.book;
                if( ss.tutorname ) bbs.tutorname = ss.tutorname;
                if( ss.classname ) bbs.classname = ss.classname;
                if( ss.category  ) bbs.category  = ss.category;
                if( ss.team      ) bbs.team      = ss.team;
                if( ss.staff     ) bbs.staff     = ss.staff;
                if( ss.state     ) bbs.state     = ss.state;
                if( ss.btype     ) bbs.btype     = ss.btype;
            }

            bbs.doSearch();
        });

        return {pop,bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>