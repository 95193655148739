<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">Refund 지급하기</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">

                <div>강사명 : {{ pop.info.tutorname }}</div>
                <div class="dp-table w-100per mt-10">
                    <input type="text" v-model.trim="pop.classname" class="w-300px h-30px float-left" placeholder="클래스 명을 입력해 주세요.">
                    <button @click="pop.doInit" class="btn-default h-30px float-left ml-10">검색</button>
                </div>

                <div class="mt-50 mb-20">
                    <table class="tbl-light-list">
                        <colgroup>
                            <col width="60">
                            <col width="*">
                            <col width="80">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>클래스명</th>
                                <th>지급하기</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in pop.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.classname }}</td>
                                <td>
                                    <span @click="pop.doSubmit(irow)" class="btn-view">지급하기</span>
                                </td>
                            </tr>
                            <tr v-if="pop.total==0">
                                <td colspan="3">일치하는 클래스가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="pop.total" :list_per_page="pop.rows" v-model="pop.page" @change="pop.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';

export default {
    name: 'RefundPaymentPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            classList : [], classTotal : 0,

            classname : "",

            page : 1,
            rows : 10,

            idx : 0,
            info : {},
            list : [], total : 0,


            doSubmit : (o) => {
                let params = {
                    idx : pop.idx,
                    idx_class : o.idx,
                    classname : o.classname
                };

                axios.post("/rest/resourceManagement/setCTMBookRefund", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("지급하였습니다.");
                        emit('close');
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doInit : () => {
                pop.page = 1;
                pop.doSearch();
            },

            doSearch : () => {
                if( !pop.classname ) {
                    pop.list = pop.classList;
                    pop.total = pop.list.length;
                    return;
                }

                pop.list = [];
                for(let i=0; i<pop.classTotal; i++){
                    let irow = pop.classList[i];

                    if( irow.classname.toLowerCase().indexOf(pop.classname.toLowerCase()) < 0 ) continue;
                    irow.page = Number((pop.list.length / pop.rows).toFixed(0)) + 1;

                    pop.list.push(irow);
                }

                pop.total = pop.list.length;
            },

            doSearchInfo : () => {
                let params = {
                    idx : pop.idx
                };

                axios.get("/rest/resourceManagement/getBookRentalInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;

                        pop.getClassList();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            getClassList : () => {
                let params = {
                    tutor     : pop.info.idx_tutor,
                    tutortype : pop.info.tutortype
                };

                axios.get("/rest/component/getClassList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.classList  = res.data.list;
                        pop.classTotal = res.data.total;

                        pop.list  = pop.classList;
                        pop.total = pop.classTotal;
                        for(let i=0; i<pop.total; i++){
                            let irow = pop.list[i];
                            irow.page = Number((i / pop.rows).toFixed(0)) + 1;
                            pop.list[i] = irow;
                        }

                    } else {
                        // if(res.data.err_msg) toast.error(res.data.err_msg);
                        console.log(res.data);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx = props.idx;

            if( pop.idx > 0 ){
                pop.doSearchInfo();
            }
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>